.app-home {
  scroll-behavior: smooth;
  width: 100%;
  transition: all 1 ease-in-out;
  &__heading {
    display: flex;
    @apply flex justify-start items-center gap-3 mt-5;
    h2 {
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__hero {
    @apply w-full mt-5 p-7 rounded-[8px];
    background-image: url("../../img/BlueBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    &-header {
      display: flex;
      @apply flex items-center text-white text-[18px] font-[700] gap-3;
      svg {
        height: auto;
        @apply w-11;
      }
    }
    p {
      font-size: 13px;
      @apply text-white mt-1 mr-5;
    }
    button {
      font-size: 12px;
      background-color: #EBF1FF;
      height: 40px;
      width: 150px;
      color: #045CF4;
      border-radius: 7px;
      margin-top: 8px;
      a {
        padding: 12px;
        @apply p-3 px-8;
      }
    }
  }

  &__heroMax {
    @apply w-full mt-5 p-10 rounded-[9px];
    background-image: url("../../img/BlueBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    &-header {
      display: flex;
      @apply flex items-center text-white text-[22px] font-[700] gap-3;
    }
    p {
      font-size: 15px;
      @apply text-white mt-3 mr-20;
    }
    button {
      font-size: 12px;
      background-color: #EBF1FF;
      height: 40px;
      width: 150px;
      color: #045CF4;
      border-radius: 7px;
      margin-top: 15px;
      scale: 1.1;
      a {
        padding: 12px;
        @apply p-3 px-8;
      }
    }
  }

  &__networks {
    width: 100%;
    @apply w-full flex flex-col items-center gap-3 mt-5;
    h2 {
      font-size: 22px;
      @apply font-[550] md:self-start;
    }
  }

  &__soon {
    width: 100%;
    @apply w-full flex flex-col items-center gap-3 mt-5;
    h2 {
      font-size: 22px;
      font-weight: 500;
      align-self: start;
      span {
        font-size: 12px;
        color: #717276;
      }
    }
    .soon-container {
      width: 100%;
      @apply w-full flex flex-wrap justify-center md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-2;
      &__card {
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        @apply relative min-w-[340px] h-[320px] lg:min-w-[300px] bg-white rounded-[10px] p-7;
        div {
          font-size: 14px;
          font-weight: 700;
          @apply flex items-center gap-3;
          svg {
            height: auto;
            @apply w-10;
          }
        }
        p {
          font-size: 12px;
          @apply mt-4;
        }
        button {
          position: absolute;
          bottom: 28px;
          font-size: 12px;
          background-color: #E8F0FF;
          height: 50px;
          width: 170px;
          color: #BDC2E1;
          border-radius: 7px;
        }
      }

      &__cardMax {
        @apply relative min-w-[340px] h-[350px] lg:min-w-[300px] bg-white rounded-[10px] p-10;
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        div {
          font-size: 14px;
          font-weight: 700;
          @apply flex items-center gap-3;
          svg {
            height: auto;
            @apply w-10;
          }
        }
        p {
          font-size: 13.5px;
          @apply mt-4;
        }
        button {
          position: absolute;
          bottom: 40px;
          font-size: 12px;
          background-color: #E8F0FF;
          height: 60px;
          width: 170px;
          color: #BDC2E1;
          border-radius: 8px;
          scale: 1.05;
        }
      }
    }
  }

  /* &__info {
    @apply grid gap-8 md:grid-cols-3 sm:grid-cols-2;
    &-box {
      @apply bg-white py-7 px-9 rounded-lg;
      span {
        @apply font-normal text-grey-500;
      }
      h5 {
        @apply text-black-400 text-3xl font-semibold mt-3;
      }
    }
  }

  &__chart {
    @apply pt-11 pb-5 sm:px-6 px-4 bg-white rounded-lg mt-12;

    &-heading {
      @apply font-normal flex items-center justify-between mb-4;
      h6 {
        @apply font-medium;
      }
      .project-legend {
        @apply flex items-center text-sm text-grey-500;
        .chart-color {
          @apply bg-blue w-2 h-2 mr-4;
        }
      }
    }
  }

  &-empty {
    @apply bg-white rounded-lg w-full flex flex-col items-center justify-center py-32 px-10 mt-8;

    p {
      @apply md:text-lg text-grey-300 mt-6 text-center;
    }
    a {
      @apply text-blue mt-4 text-center;
    }
  } */
}
