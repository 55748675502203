.app-header {
  @apply flex w-[calc(100%-270px)] h-[60px] items-center justify-between py-8 md:px-11 px-6 bg-white fixed z-10 top-0 right-0;
  @media screen and (min-width: 1720px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  border-bottom: 1px solid rgba(18, 18, 18, 0.1);
  &__options {
    @apply flex items-center;
    button {
      @apply xl:hidden block w-5 h-5 mr-4;
    }
  }
  &__path {
    &-main {
      @apply text-black-300;
    }
    &-sub {
      @apply text-grey-100;
    }
  }
  &__dropdown {
    @apply relative;
    button {
      @apply flex items-center;
      .arrow {
        @apply ml-3 fill-grey-300;
      }
    }
    &_cont {
      @apply bg-white rounded-lg text-[14px] px-7 py-5 absolute top-9 right-0;
      box-shadow: 0 12px 10px rgba(0, 103, 245, 0.059);
      ul {
        li {
          @apply bg-white py-3;
          &:last-child {
            @apply border-t;
            border-color: rgba(31, 31, 31, 0.1);
          }
          button,
          a {
            @apply flex items-center;
            svg {
              @apply mr-3;
            }
          }
        }
      }
    }
  }
}
