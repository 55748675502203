#RFS-StepperContainer {
  .active,
  .completed {
    background-color: #045cf4 !important;
  }
  .completed ~ #RFS-LabelContainer {
    span {
      color: #045cf4 !important;
    }
  }
  padding: 0 !important;
  & > * {
    padding: 0 !important;
  }
  .StepButton-0-2-4 {
    // background-color: blue !important;
  }
}
