.rangeslider {
  background: rgba(#045cf4, 0.25) !important;
}
.rangeslider__fill {
  background-color: #045cf4 !important;
}
.rangeslider .rangeslider__handle {
  box-shadow: none !important;
  width: 20px !important;
  height: 20px !important;
  border: none !important;
  background-color: #045cf4 !important;
}
.rangeslider .rangeslider__handle:focus {
  outline: none !important;
}
.rangeslider-horizontal {
  height: 5px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  display: none !important;
}

.rangeslider__handle-tooltip {
  background-color: #818683 !important;
  color: #fff !important;
  font-weight: 500;
  // visibility: hidden !important;
}
.rangeslider__handle-tooltip::after {
  border-top-color: #818683 !important;
}
.rangeslider__handle:focus {
  outline: 1px auto #3c4252 !important;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none !important;
}
