.app-projects {
  &__heading {
    @apply grid sm:grid-cols-2 gap-6 items-center justify-between mb-11;
    h6 {
      @apply text-2xl font-normal text-grey-400;
    }
    a {
      @apply sm:ml-auto;
    }
  }
  &__sub-heading {
    @apply text-xl font-normal text-black-400 mb-8;
  }

  &__available {
    @apply mb-16;
    &-list {
      @apply grid sm:grid-cols-2 md:grid-cols-3 md:grid-cols-4 gap-5;
      &-item {
        @apply flex items-center justify-center rounded-lg bg-white py-4;
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        img {
          @apply mr-4 w-7 h-7;
          @apply mr-4;
          max-width: 2rem;
        }
      }
    }
  }

  &__history {
    .see-more {
      @apply flex justify-center mt-6;
      button {
        @apply flex items-center;

        svg {
          @apply ml-2 fill-blue;
        }
      }
    }

    &-empty {
      @apply bg-white rounded-lg w-full flex flex-col items-center justify-center py-24;

      p {
        @apply text-lg text-grey-300 mt-6;
      }
      a {
        @apply text-blue mt-4;
      }
    }
  }
}

.app-project {
  &__heading {
    @apply flex items-center justify-between mb-8;

    &-name {
      @apply flex items-center;
      img {
        @apply w-11 h-11;
      }
      h6 {
        @apply text-2xl text-black-400 ml-3;
      }
    }

    &-dropdown {
      @apply relative;
      button {
        @apply flex items-center;
        svg {
          margin-left: 6px;
          width: 13px;
          height: 13px;
          fill: rgba(18, 18, 18, 0.6);
        }
      }
      &-cont {
        @apply bg-white rounded-lg px-7 py-5 absolute top-9 right-0;
        box-shadow: 0 12px 10px rgba(0, 103, 245, 0.06);
        ul {
          li {
            @apply bg-white py-3;
            button,
            a {
              @apply flex items-center;
            }
          }
        }
      }
    }
  }

  &__details {
    @apply grid gap-8 sm:grid-cols-4 grid-cols-2 bg-white sm:p-11 py-6 px-5 rounded-lg;
    &-box {
      span {
        @apply text-sm text-grey-500;
      }
      p {
        @apply text-black-400 mt-1;
      }
      img {
        @apply w-7 h-7;
      }
    }
  }

  &__chart {
    @apply h-96 pt-11 pb-16 sm:px-6 px-4 bg-white rounded-lg mt-12;

    &-heading {
      @apply font-normal flex items-center justify-between mb-4;
      h6 {
        @apply font-medium;
      }
      .project-legend {
        @apply flex items-center text-sm text-grey-500;
        .chart-color {
          @apply bg-blue w-2 h-2 mr-4;
        }
      }
    }
  }
}

.create_network {
  @apply bg-white rounded-lg md:py-12 md:px-11 px-5 pt-7 pb-9;
  box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);

  &-box {
    @apply mt-8;
  }

  .codeSnippet__head {
    background: rgb(40, 42, 54);
    color: rgb(248, 248, 242);
  }
}

.select_network {
  // @apply ;
  h6 {
    @apply text-xl text-black-300 mb-12;
  }

  &-options {
    @apply grid gap-5 lg:grid-cols-4 md:grid-cols-2;
    &.service {
      @apply lg:grid-cols-3;
      .select_network-options__box {
        @apply py-14;
      }
    }
    &__box {
      @apply flex flex-col items-center rounded-lg py-9 text-black-300 transition-all duration-200;
      border: 1px solid rgba(18, 18, 18, 0.1);
      &.disabled {
        @apply relative;

        .disabled_box {
          @apply text-xl absolute top-0 left-0 w-full h-full flex items-center justify-center cursor-not-allowed opacity-0 transition-all duration-300;
          background-color: rgba(10, 46, 101, 0.2);
        }
        &:hover {
          background-color: none !important;
        }
      }
      img {
        @apply w-9 h-9 mb-3;
      }
      &:hover {
        background-color: rgba($color: #0060ff, $alpha: 0.1);
        &.disabled {
          .disabled_box {
            @apply opacity-100;
          }
          &:hover {
            background-color: none !important;
          }
        }
      }
      &.selected {
        border-color: #0060ff;
      }
    }
  }

  &-btns {
    @apply flex justify-between mt-14 py-9;
  }
}

.selected_cloud {
  h6{
    @apply text-xl text-black-300
  }
  &-options{
    @apply flex justify-around
  }
}
