.side-nav {
  @apply w-[270px] h-screen min-h-[700px] flex-col justify-between relative left-[-270px] xl:left-0 top-0 z-[99] bg-[#0C0F20];
  width: 270px;

  &__header {
    @apply w-full h-[70px] flex items-center justify-between pl-10 pt-5;
    .navcontroller {
      @apply mr-5;
    }
  }

  &__cont {
    @apply flex-grow flex flex-col mx-5 justify-between mt-0;

    &-bottom {
      &-links {
        @apply mb-10;
        .nav_link {
          display: flex;
          svg {
            @apply mr-4 fill-white;
          }
          span {
            @apply text-white;
          }
          &.doc {
            @apply justify-between;
          }
        }
      }
      &-socials {
        @apply py-9 px-12 flex items-center justify-between;
      }
    }
    &-top {
      @apply mt-2;
      .nav_link {
        display: flex;
        svg {
          @apply mr-4 fill-grey;
        }
        span {
          @apply text-white;
        }
      }
    }
  }

  .nav_link {
    @apply items-center h-[50px] px-5 rounded-[10px];
    transition: all .4s ease;
    border: 1px solid #0C0F20;
    &:hover {
      border-color: #0060FF;
    }
    &.active {
      &:hover {
        border-color: #0C0F20;
      }
      background-color: #1C2241;
      svg {
        stroke-width: 0.5px;
        path {
          fill: white;
        }
      }
      span {
        @apply text-white;
      }
    }

    svg {
      stroke-width: 1.2px;
    }
    
    span {
      font-size: 15px;
      font-weight: 400;
    }
  }
}


.min-nav {
  @apply w-[70px] h-screen flex flex-col justify-between fixed left-0 top-0 z-[99] bg-[#0C0F20];
  width: 70px;

  &__header {
    @apply w-full h-[70px] flex items-center justify-center pt-5;
    height: 70px;
    .navcontroller {
      @apply border-[1.5px] border-[#333E77] h-[30px] px-[6px];
      border-radius: 2.5px;
    }
  }

  &__cont {
    @apply flex-grow flex flex-col justify-between;

    &-bottom {
      &-links {
        @apply mb-10;
        .nav_link {
          svg {
            @apply fill-white;
          }
          span {
            @apply text-white;
          }
          &.doc {
            @apply justify-between;
          }
        }
      }
      &-socials {
        @apply py-9 px-12 flex items-center justify-between;
      }
      span {
        @apply hidden absolute flex justify-center w-[120px] left-[80px] text-white text-[12px] text-center p-1 bg-[#0C0F20] rounded-md;
      }
    }
    &-top {
      @apply mt-2;
      .min_link {
        span {
          @apply text-white text-lg;
        }
      }
      span {
        @apply hidden absolute flex justify-center w-[120px] left-[80px] text-white text-[12px] text-center p-1 bg-[#0C0F20] rounded-md;
      }
    }
  }

  .min_link {
    @apply flex items-center justify-center h-[40px] w-[40px] rounded-[5px];
    transition: all .4s ease;
    border: 1px solid #0C0F20;
    &:hover {
      border-color: #0060FF;
    }
    &.active {
      &:hover {
        border-color: #0C0F20;
      }
      background-color: #1C2241;
      span {
        @apply text-white;
      }
      svg {
        stroke-width: 0.5px;
        path {
          fill: white;
        }
      }
    }
    span {
      font-size: 15px;
      font-weight: 400;
    }
    svg {
      stroke-width: 1.2px;
    }
    span {
      @apply hidden absolute flex justify-center w-[120px] left-[80px] text-white text-[12px] text-center px-1 bg-[#0C0F20] rounded-md;
    }
  }
}