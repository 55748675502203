.support-page {
  @apply w-full;
  &__header {
    @apply w-full flex items-center justify-between my-5;
    h2 {
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__body {
    @apply w-full;
    &-empty {
      border-radius: 8px;
      @apply w-full h-[70vh] bg-white flex flex-col items-center justify-center;
    }
    &-tickets {
      @apply mt-10;
    }
    &-form {
      @apply w-full min-h-[70vh] bg-white flex flex-col items-center p-5 md:p-10 2xl:px-16 ;
      border-radius: 8px;
    }
    &-sendMail {
      border-radius: 8px;
      @apply w-full min-h-[70vh] bg-white flex flex-col px-3 md:px-10 2xl:px-20 py-10;
    }
  }

  .create-ticket {
    @apply flex items-center justify-between px-4 py-[10px] gap-4 bg-blue;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 100;
    svg {
      scale: 0.9;
    }
  }

  .drop-zone {
    @apply w-full h-[200px] flex flex-col items-center justify-center;
    border: 2px dashed #D8D8D8;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .file-container {
    @apply w-full bg-[#F0F1F4] h-[150px] flex flex-col justify-between items-center mt-5 py-5 px-8;
    border-radius: 8px;
  }

  .support-tickets {
    @apply w-full flex flex-col;

    &__display {
      @apply w-full relative min-h-[60vh] flex flex-col justify-between gap-5 bg-white mt-10 pt-7;
      border-radius: 8px;
      box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
      &-header {
        @apply w-full flex flex-col gap-4 md:flex-row md:justify-between items-center px-7;
        font-size: 18px;
        font-weight: 600;
      }
      &-footer {
        @apply w-full flex items-center justify-end h-12 mb-2 px-7 gap-2;
        .button {
          @apply w-8 h-8 flex items-center justify-center bg-white;
          border: 0.5px solid #D4D4D4;
          border-radius: 7px;
        }
      }
    }
  }
}