.ReactModal__Content {
  @apply w-[calc(100%-30px)] max-w-[1000px];
  border-radius: 13px;
  border: none;
  box-shadow: 0px 8px 46px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.03);

  @media screen and (min-width: 500px) {
    width: calc(100% - 80px);
    max-width: 1000px;
  }
}
.ReactModal__Overlay {
  z-index: 999999999;
  background-color: #000000c4 !important;
}

//  !important;
