.app-table {
  @apply overflow-x-auto relative w-full;
  // min-width: 900px;
  &__heading {
    @apply grid justify-between items-center;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr;

    & > * {
      @apply flex-grow py-3 px-6 text-sm bg-transparent fill-grey-300 whitespace-nowrap;
    }
  }

  &__body {
    @apply sm:w-full w-max;
    &-row {
      @apply grid  justify-between items-center bg-white rounded-lg mb-2 sm:w-full w-max;
      grid-template-columns: 50px 1fr 1fr 1fr 1fr;
      & > * {
        @apply py-6 px-6 text-black-300;
      }
      &-name {
        @apply flex items-center;
        img {
          @apply mr-4 w-7 h-7;
          @apply mr-4;
          max-width: 2rem;
        }
      }
    }

    &-status {
      @apply w-24;
    }
  }

  &__status {
    @apply w-24;
  }

  .table_status {
    @apply bg-[#ff8c00] w-2 h-2 bg-black-300 rounded-full;
    &.active {
      background-color: #00e1b6;
    }
    &.processing,
    &.pending {
      background-color: #ff8c00;
    }
    &.deactivated {
      background-color: #f00;
    }
  }
}
