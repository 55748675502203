.cloud_provider {
  display: flex;
  justify-content: space-between;
  width: 55%;
  gap: 15px;
  .button {

    background: #ffffff;
    align-items: center;
    width: 286px;
    height: 125px;
    padding: auto;
    padding-top: 1rem;
    border-radius: 4px;
    justify-content: center;
    margin-left: auto;
 
   
    &:hover {
      background-color: rgba($color: #0060ff, $alpha: 0.1);
      &.disabled {
        .disabled_box {
          @apply opacity-100;
        }
        &:hover {
          background-color: none !important;
        }
      }
    }
    &.selected {
      border-color: #0060ff;
      border: 1px solid;
    }
    &.content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:62px;
        height:82px;
        gap: 12px;
        background-color: red;
     

      }
    h6 {
      @apply text-sm justify-center
    }

  

    // flex flex-col w-[136px] h-[71px] justify-center items-center border-solid border-1 border-indigo-600 rounded-sm
  }
}
