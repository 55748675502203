.api-networks {
  transition: all 0.4 ease;
  @apply w-full flex flex-wrap justify-center md:grid md:grid-cols-3 lg:grid-cols-4 gap-5 mt-2;
  @media screen and (min-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  &__card {
    border: 1px solid #E4E4E4;
    transition: all 0.4 ease;
    &:hover {
      border-color: #045CF4;
    }
    @apply relative bg-white h-[270px] 2xl:h-[300px] rounded-[10px] w-[300px] md:w-auto p-5;
    div {
      display: flex;
      @apply h-14;
      svg {
        height: auto;
        @apply w-14;
      }
    }
    h2 {
      font-size: 15px;
      @apply font-[700] mt-6 2xl:mt-10;
    }
    p {
      font-size: 11px;
      @apply 2xl:text-[13px] mt-3 2xl:mt-5;
    }
    button {
      position: absolute;
      bottom: 28px;
      font-size: 15px;
      background-color: #689DF8;
      height: 40px;
      width: 100px;
      color: white;
      border-radius: 8px;
    }
  }

  &__cardMax {
    border: 1px solid #E4E4E4;
    transition: all 0.4s ease;
    &:hover {
      border-color: #045CF4;
    }
    @apply relative bg-white h-[340px] rounded-[10px] w-[300px] md:w-auto p-7;
    div {
      display: flex;
      @apply h-14;
      svg {
        height: auto;
        @apply w-16;
      }
    }
    h2 {
      font-size: 17px;
      @apply font-[700] mt-10;
    }
    p {
      font-size: 15px;
      @apply mt-5;
    }
    button {
      position: absolute;
      bottom: 28px;
      font-size: 15px;
      background-color: #689DF8;
      height: 40px;
      width: 100px;
      color: white;
      border-radius: 8px;
      scale: 1.1;
    }
  }
}