.app_auth {
  @apply bg-white w-full py-10 lg:px-12 sm:px-8 px-6 rounded-lg;
  h6 {
    @apply text-2xl mb-14;
  }

  &-info {
    @apply mt-0;
    p {
      @apply text-center text-black-300 text-sm font-light;
    }
    a {
      @apply text-blue underline;
    }
  }
}

.app_verification {
  @apply flex flex-col items-center;
  h5,
  p {
    @apply text-center;
  }
  h5 {
    @apply text-3xl text-white;
  }
  p {
    @apply mt-3;
    color: #f6f6f6;
  }
  a {
    @apply text-[#F6F6F6] mt-8 underline;
    color: #f6f6f6;
  }
}

.password_check {
  @apply mt-6 ml-5;
  &__option {
    @apply flex items-center font-light text-grey-300 mt-2;
    &-box {
      @apply w-3 h-3 bg-grey-300 rounded-full mr-4;
    }
    &.passed {
      @apply text-black-300;
      .password_check__option-box {
        background-color: #00e1b6;
      }
    }
  }
}
