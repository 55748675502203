.insight-container {
  @apply w-full relative max-w-[350px] lg:max-w-none sm:w-auto bg-white rounded-[8px] p-5 2xl:p-8 justify-center;
  box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);

  .header-text {
    font-size: 16px;
    font-weight: 400;
  }

  .info-text {
    @apply w-full flex items-center;
    font-size: 13px;
    span {
      font-size: 11px;
      border-radius: 10px;
      padding-left: 5px;
      padding-right: 5px;
      gap: 2px;
      @apply py-1 mr-1 flex items-center;
    }
  }

  .user-icons {
    @apply flex items-center;
  }

  .grid-line {
    border-top: 0.5px dashed #eeeeee;
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
  }

  .bar-chart {
    height: calc(100% - 12.5%);
    @apply w-full absolute left-0 bottom-[30px] grid grid-cols-4 gap-4 px-5 items-end;
  }
}
