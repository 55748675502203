.block-app {
  @apply flex flex-row bg-[#F8F8F8];
  display: flex;
  &__view {
    @apply w-full absolute right-0 bg-[#F8F8F8] flex-grow h-screen max-h-screen pt-[65px] overflow-y-auto;

    .main {
      @apply px-4 md:px-10 pb-20;
      @media screen and (min-width: 1720px) {
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }

  &-auth {
    @apply bg-[#090c21] h-screen flex flex-col pb-12;

    &__header {
      @apply py-14 md:px-32 px-6;
    }
    &-cont {
      @apply flex justify-center h-full pb-12 overflow-y-auto;
      // height: calc(100vh - 144px);
    }
    &-body {
      @apply sm:max-w-xl max-w-[90%] sm:min-w-[556px] min-w-[90%] h-full;
    }
  }
}
