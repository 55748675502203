.api-services {
  @apply flex flex-col;
  scroll-behavior: smooth;
  width: 100%;
  transition: all 1 ease-in-out;

  &__available {
    @apply w-full mt-5 flex flex-col items-start gap-3;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .networks {
      @apply w-full h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 2xl:gap-7;

      .box {
        @apply flex items-center bg-white gap-2 p-2;
        font-size: 15px;
        font-weight: 600;
        border: 1px solid #ffffff;
        border-radius: 15px;
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        &:hover {
          border: 1px solid #045cf4;
        }
        @media screen and (min-width: 765px) {
          font-size: 12px;
        }
        @media screen and (min-width: 950px) {
          font-size: 15px;
        }
      }
    }
  }

  &__connected {
    @apply w-full mt-5 flex flex-col items-start gap-3;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .box {
      @apply w-full min-h-[60px] flex items-center justify-between bg-white py-1 px-5;
      font-size: 15px;
      font-weight: 600;
      border: 1px solid #ffffff;
      border-radius: 15px;
      box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
    }

    .avatars {
    }

    .options {
      @apply w-5 flex justify-center;
    }
  }

  &__active {
    @apply w-full mt-10 flex flex-col items-start gap-3;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .container {
      @apply w-full flex flex-col gap-3 p-0;

      .box {
        @apply flex items-center w-full bg-white p-1 px-7;
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        border-radius: 16px;
        font-size: 15px;
        font-weight: 600;

        &__header {
          @apply relative flex items-center w-full px-7;
          font-size: 14px;
          color: #12121299;
        }
      }
    }
  }
}

.project-insight {
  @apply flex flex-col w-full gap-6 text-black;

  &__hero {
    @apply w-full h-auto flex flex-col md:flex-row items-center gap-6 p-8 bg-white;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);

    @media screen and (min-width: 1720px) {
      @apply pr-40;
    }
    border-radius: 10px;

    &-card {
      @apply flex flex-col items-center border-[2px] border-blue pt-10;
      width: 350px;
      min-width: 350px;
      height: 365px;
      border-radius: 10px;

      svg {
        width: 118px;
        height: 118px;
      }

      h2 {
        @apply mt-3;
        font-size: 22px;
        font-weight: 500;
      }

      p {
        @apply mt-20 text-18;
        font-weight: 500;

        span {
          color: #aeafb2;
        }
      }
    }
  }
  &__api {
    @apply flex flex-col bg-white w-full p-8 gap-6 relative text-black;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    &-title {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &__graph {
    @apply w-full bg-white px-8 pt-8 text-black;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    &-text {
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: 500;
      span {
        font-size: 14px;
        color: #767676;
      }
    }
  }
}
