.dedicated {
  @apply w-full mt-5 flex flex-col;

  &__page {
    @apply flex flex-col gap-7;

    &-empty {
      @apply w-full h-[70vh] bg-white flex flex-col items-center justify-center gap-5;

      box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
      border-radius: 8px;
    }

    h2 {
      font-weight: 700;
      font-size: 18px;
    }
  }

  .button {
    font-size: 15px;
    font-weight: 200;
    color: #ffffff;
    background-color: #045cf4;
    height: 45px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 7px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 20px;
    a {
      padding: 16px;
      @apply p-3 px-4;
    }
  }
}

.create {
  @apply w-full mt-5 flex flex-col gap-5;

  &__back {
    @apply flex gap-2 items-center;

    font-size: 13px;
    font-weight: 600;
  }
}

.step {
  @apply w-full bg-white flex flex-col items-center p-5 md:py-8 xl:px-7;

  box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
  border-radius: 8px;

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .slider {
    @apply w-full mt-8;

    font-size: 14px;

    &__header {
      @apply flex items-center;

      &-button {
        @apply w-[50%] text-center py-2;
      }
    }

    &__border {
      @apply w-full h-[5px] bg-[#EAEAEA] rounded-[5px] flex;
    }
  }
}

.dedicatedProject {
  &__header {
    @apply mt-5 flex items-center justify-between;

    &-btn {
      @apply flex w-[60px] flex-col items-center justify-between gap-1;

      font-size: 10px;
      color: #1f1f1f99;
    }
  }

  &__insight {
    @apply w-auto bg-white px-5 flex flex-col justify-center;

    box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
    border-radius: 16px;

    &-title {
      font-size: 12px;
      color: #1f1f1f99;
    }

    &-value {
      @apply truncate;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &__dataBox {
    @apply flex flex-col gap-3 md:grid grid-cols-3 mt-10;
  }

  &__rpcApi {
    @apply w-full grid md:grid-cols-2 mt-7 gap-3 md:gap-5 2xl:gap-10;

    &-value {
      font-size: 14px;
    }
  }
}
