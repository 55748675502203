.user {
  @apply w-full mt-10 flex flex-col items-center;
  &__hero {
    @apply w-full flex flex-col items-center sm:grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5;
  }
}

.users-list {
  @apply w-full flex flex-col mt-10;

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
  &__display {
    @apply w-full relative min-h-[60vh] flex flex-col justify-between gap-5 bg-white mt-3 pt-7;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
    &-header {
      @apply w-full flex flex-col gap-4 md:flex-row md:justify-between items-center px-7;
      .date-btn {
        @apply w-[135px] bg-white h-9 flex items-center justify-between px-4;
        border: 1px solid #ececec;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
      }
      .service-btn {
        @apply w-[135px] bg-white h-9 hidden md:flex items-center justify-between px-4;
        border: 1px solid #ececec;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
      }
      .filter-btn {
        @apply relative w-[135px] bg-[#F1F1F1] h-9 flex items-center justify-between px-4;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    &-footer {
      @apply w-full flex  items-center md:justify-center h-16 mb-2 px-7 gap-2;
      .prev-btn {
        @apply w-[45px] h-[40px] bg-white flex items-center justify-center;
        border: 1px solid #045cf4;
        border-radius: 8px;
        svg {
          fill: #045cf4;
        }
      }

      .next-btn {
        @apply w-[130px] h-[40px] bg-blue flex items-center justify-between px-3;
        border: 1px solid #045cf4;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 200;
        color: white;
        svg {
          fill: white;
        }
      }
    }

    .dropdown-container {
      @apply absolute w-[230px] top-14 right-0 bg-white px-5 py-3;
      box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;

      .dropdown-option {
        @apply bg-white w-full flex items-center gap-2 py-3 pl-3 text-start cursor-pointer;
        font-size: 14px;
        color: #717171;
      }

      .rule {
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }
}

.user-insight {
  @apply relative max-w-[1000px] h-[85vh] xl:h-[75vh] overflow-scroll max-h-[600px] flex flex-col items-center md:flex-row justify-between px-5 lg:px-10 py-7 bg-white;
  border-radius: 8px;
  color: #00000051;
  font-size: 14px;

  &__info {
    @apply w-full xl:w-[48%] h-[90%] mt-10 flex flex-col items-start gap-3;
    color: black;
  }

  &__services {
    @apply w-full xl:w-[48%] h-[90%] bg-white px-4 lg:px-7 py-7 mt-10;
    border: 1px solid #d0d0d0;
    border-radius: 12px;
  }
}
