.app-settings {
  @apply w-full lg:w-[55vw] xl:min-h-[400px] max-w-[800px] bg-white shadow-[0px_4px_20px_rgba(91,71,188,0.05)] px-5 py-10 md:p-10;
  &__heading {
    @apply mb-12;
    h6 {
      @apply text-2xl;
    }
  }
  &__user {
    .user_avatar {
      @apply relative w-14 h-14 rounded-full overflow-hidden mr-6;
      svg {
        @apply w-14 h-14;
      }

      .camera {
        @apply absolute top-0 left-0 w-full h-full bg-transparent flex items-center justify-center;
        background-color: rgba(18, 18, 18, 0.6);
        svg {
          @apply w-6 h-6;
        }
      }
    }
    @apply flex items-center;

    p {
      @apply text-2xl text-black-300;
    }

    &-form {
      @apply mt-12;
    }
  }
}
